/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "timer": {
    "title_size_default": "text-headline-sm md:text-headline-md mb-3 md:mb-4.5",
    "duration_label_size_default": "text-headline-2xs md:text-headline-xs",
    "extend": {
      "duration_size_default": "md:w-20 md:h-[70px] text-headline-md md:text-headline-lg",
      "duration_colors_default": "border-yellow-sea-400",
      "duration_variant_default": "border"
    }
  },
  "button": {},
  "logo_size_default": "w-36",
  "logo_colors_default": "",
  "logo_variant_default": "hidden md:block absolute top-6 left-6",
  "description_size_default": "text-headline-xs md:text-headline-sm md:max-w-[820px]",
  "description_colors_default": "",
  "description_variant_default": "text-center",
  "size_default": "p-6 gap-4 md:gap-5",
  "colors_default": "bg-yellow-sea-100 bg-[url(/image/course/confirmation-banner-background.svg)] bg-cover bg-bottom",
  "variant_default": "flex flex-col items-center relative"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "CourseCountdown");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;