import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { useAppState } from '@/hooks/useAppState';
import useUserAuth from '@/hooks/useUserAuth';
import FakeBackgroundDesktop from '@/mabra/public/image/contentLock/fake-content-desktop.jpg';
import FakeBackgroundMobile from '@/mabra/public/image/contentLock/fake-content-mobile.jpg';
import { StandaloneContentLock } from 'base/components/ContentLock';
import NextImage from 'next/image';
import { useEffect, useRef } from 'react';

export const MabraStandaloneContentLock: typeof StandaloneContentLock = ({ lockedUntil, ...props }) => {
  const { logInUrl } = useUserAuth();
  const [{ type, device }] = useAppState();
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => ref.current?.showModal(), [ref]);

  const isLockedUntil = lockedUntil && Date.now() < lockedUntil;

  const content = isLockedUntil
    ? {
        title: 'Det här innehållet är inte tillgängligt än',
        button: {
          content: 'Klicka här för att läsa mer',
          link: { href: '/21-dagar' },
        },
        footer: {
          content: 'Redan medlem och deltar i vår utmaning? Klicka här',
          href: '/21-dagar-start',
        },
      }
    : {
        title: 'Det här innehållet är en del av MåBra:s utmaning 21 dagar',
        button: {
          content: 'Klicka här för att läsa mer',
          link: { href: '/21-dagar' },
        },
        footer: {
          content: 'Redan medlem och deltar i vår utmaning? Klicka här för att logga in',
          href: logInUrl,
        },
      };

  const purchase = (
    <>
      {type === 'front' && (
        <NextImage src={device === 'desktop' ? FakeBackgroundDesktop : FakeBackgroundMobile} alt="" />
      )}
      <dialog
        onClose={() => ref.current?.showModal()}
        onCancel={() => ref.current?.showModal()}
        ref={ref}
        className={
          'not-prose flex flex-col items-center gap-y-6 p-8 text-center shadow-[0px_0px_14px_rgba(0,0,0,0.25)] outline-none md:max-w-2xl [&::backdrop]:backdrop-blur'
        }
      >
        <h3 className="m-0 text-headline-sm sm:text-headline-lg">{content.title}</h3>
        <Button {...content.button} />
        <Link
          {...content.footer}
          options={{ className: 'text-body-sm sm:text-body-md text-cherry-blossom-500 underline' }}
        />
      </dialog>
    </>
  );

  return <StandaloneContentLock content={{ purchase }} {...props} />;
};
