import { Icon } from '@/components/Icon';
import { useStableProps } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { getUrl } from '@/utils/getUrl';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneCourseCountdown } from 'base/components/CourseCountdown';
import Tracking from 'base/components/TrackingComponent';
import getConfig from 'modules/config';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const getMyPageCountdownCopy = ({ isUserSubscribedForCourse }: { isUserSubscribedForCourse: boolean }) => {
  if (isUserSubscribedForCourse) {
    return {
      title: 'Vi är igång! I 21 dagar kommer vi peppa och coacha dig till nya hälsosamma vanor.',
      description: '',
      button: {
        content: (
          <>
            Gå till utmaningen
            <Icon name="chevronRight" />
          </>
        ),
        link: { href: '/21-dagar-start' },
      },
    };
  }

  return {
    title: 'ANTAR DU UTMANINGEN?',
    description:
      'Nya kost- och träningsvanor på 21 dagar. Få träningsschema, kostplaner och daglig coachning direkt i din mejl. Den 2 september kör vi!',
    button: {
      content: (
        <>
          Läs mer och anmäl dig här!
          <Icon name="chevronRight" />
        </>
      ),
      link: { href: '/21-dagar' },
    },
  };
};

const defaultCountdownCopy = {
  title: 'ANTAR DU UTMANINGEN?',
  description:
    'Nya kost- och träningsvanor på 21 dagar. Få träningsschema, kostplaner och daglig coachning direkt i din mejl. Den 2 september kör vi!',
  button: {
    content: (
      <>
        Läs mer och anmäl dig här!
        <Icon name="chevronRight" />
      </>
    ),
    link: { href: '/21-dagar' },
  },
};

const { closeTime } = getConfig('payments') ?? {};
const ctsUrl = getUrl('/21-dagar');

const ctsAttrs = withTrackingAttributes({
  object: 'promotion_banner',
  creative: 'promotion_banner',
  objectName: 'countdown',
  name: 'countdown',
  objectUrl: ctsUrl ? ctsUrl.toString() : '/21-dagar',
  id: ctsUrl ? ctsUrl.toString() : '/21-dagar',
  objectExtraInfo: [{ campaign: 'mabra_course_sep24', end_time: closeTime }],
  label: [{ campaign: 'mabra_course_sep24', end_time: closeTime }],
});

export const MabraStandaloneCourseCountdown: typeof StandaloneCourseCountdown = (props) => {
  const { userTags } = useUserAuth();

  const { asPath } = useRouter();
  const isMyPage = asPath.startsWith('/mina-sidor');

  const isUserSubscribedForCourse = userTags.includes('mabra_fitness_course');

  const { title, description, button } = isMyPage
    ? getMyPageCountdownCopy({
        isUserSubscribedForCourse,
      })
    : defaultCountdownCopy;

  const logo = useMemo(() => <NextImage src={BannerImage} alt={'Banner Image'} />, []);

  const stableCourseCountdown = useStableProps(
    {
      logo,
      timer: {
        title,
        finishTime: closeTime,
        finishContent: isMyPage && title,
      },
      description,
      button,
    },
    props,
  );

  if (!isMyPage || !isUserSubscribedForCourse) {
    return null;
  }

  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <StandaloneCourseCountdown {...stableCourseCountdown} />
    </Tracking>
  );
};
