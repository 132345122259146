/* THIS FILE IS GENERATED. DO NOT EDIT */

import { LabradorAdTemplate, LabradorAdTemplateProps } from './AdTemplate';
import { LabradorAdunit, LabradorAdunitProps } from './Adunit';
import { LabradorAffiliateGallery, LabradorAffiliateGalleryProps } from './AffiliateGallery';
import { LabradorAffiliateItem, LabradorAffiliateItemProps } from './AffiliateItem';
import { LabradorArticle, LabradorArticleProps } from './Article';
import { LabradorArticleFooter, LabradorArticleFooterProps } from './ArticleFooter';
import { LabradorArticleHeader, LabradorArticleHeaderProps } from './ArticleHeader';
import { LabradorArticleSlider, LabradorArticleSliderProps } from './ArticleSlider';
import { LabradorArticleTeaserBodytext, LabradorArticleTeaserBodytextProps } from './ArticleTeaser/Bodytext';
import { LabradorArticleTeaserDefault, LabradorArticleTeaserDefaultProps } from './ArticleTeaser/Default';
import { LabradorArticleTeaser, LabradorArticleTeaserProps } from './ArticleTeaser';
import { LabradorBanner, LabradorBannerProps } from './Banner';
import { LabradorBlogSlider, LabradorBlogSliderProps } from './BlogSlider';
import { LabradorBodytext, LabradorBodytextProps } from './Bodytext';
import { LabradorBox, LabradorBoxProps } from './Box';
import { LabradorBreakingNews, LabradorBreakingNewsProps } from './BreakingNews';
import { LabradorByline, LabradorBylineProps } from './Byline';
import { LabradorCheckbox, LabradorCheckboxProps } from './Checkbox';
import { LabradorColumns, LabradorColumnsProps } from './Columns';
import { LabradorContentLock, LabradorContentLockProps } from './ContentLock';
import { LabradorCourseCountdown, LabradorCourseCountdownProps } from './CourseCountdown';
import { LabradorCoverBox, LabradorCoverBoxProps } from './CoverBox';
import { LabradorCustomLinks, LabradorCustomLinksProps } from './CustomLinks';
import { LabradorEmphasis, LabradorEmphasisProps } from './Emphasis';
import { LabradorFacebook, LabradorFacebookProps } from './Facebook';
import { LabradorFactBox, LabradorFactBoxProps } from './FactBox';
import { LabradorFallback, LabradorFallbackProps } from './Fallback';
import { LabradorFront, LabradorFrontProps } from './Front';
import { LabradorHeading, LabradorHeadingProps } from './Heading';
import { LabradorImage, LabradorImageProps } from './Image';
import { LabradorInstagram, LabradorInstagramProps } from './Instagram';
import { LabradorJwVideoArticle, LabradorJwVideoArticleProps } from './JwVideo/Article';
import { LabradorJwVideoShoppable, LabradorJwVideoShoppableProps } from './JwVideo/Shoppable';
import { LabradorJwVideoTeaser, LabradorJwVideoTeaserProps } from './JwVideo/Teaser';
import { LabradorJwVideo, LabradorJwVideoProps } from './JwVideo';
import { LabradorKeesingGames, LabradorKeesingGamesProps } from './KeesingGames';
import { LabradorLabradorImage, LabradorLabradorImageProps } from './LabradorImage';
import { LabradorLineBreak, LabradorLineBreakProps } from './LineBreak';
import { LabradorList, LabradorListProps } from './List';
import { LabradorMarkup, LabradorMarkupProps } from './Markup';
import { LabradorMostRead, LabradorMostReadProps } from './MostRead';
import { LabradorNewsletterSignup, LabradorNewsletterSignupProps } from './NewsletterSignup';
import { LabradorParagraph, LabradorParagraphProps } from './Paragraph';
import { LabradorQuotebox, LabradorQuoteboxProps } from './Quotebox';
import { LabradorRadioButton, LabradorRadioButtonProps } from './RadioButton';
import { LabradorRecommendedArticles, LabradorRecommendedArticlesProps } from './RecommendedArticles';
import { LabradorRichTextBox, LabradorRichTextBoxProps } from './RichTextBox';
import { LabradorRow, LabradorRowProps } from './Row';
import { LabradorSavedArticles, LabradorSavedArticlesProps } from './SavedArticles';
import { LabradorSecondNativeArticle, LabradorSecondNativeArticleProps } from './SecondNativeArticle';
import { LabradorShowsSlider, LabradorShowsSliderProps } from './ShowsSlider';
import { LabradorSlider, LabradorSliderProps } from './Slider';
import { LabradorTagsIndex, LabradorTagsIndexProps } from './TagsIndex';
import { LabradorTextMultiline, LabradorTextMultilineProps } from './TextMultiline';
import { LabradorTextSubtitle, LabradorTextSubtitleProps } from './TextSubtitle';
import { LabradorTextTitle, LabradorTextTitleProps } from './TextTitle';
import { LabradorTiktok, LabradorTiktokProps } from './Tiktok';
import { LabradorTrendingMenu, LabradorTrendingMenuProps } from './TrendingMenu';
import { LabradorTwitter, LabradorTwitterProps } from './Twitter';
import { LabradorVideoReels, LabradorVideoReelsProps } from './VideoReels';
import { LabradorYouplay, LabradorYouplayProps } from './Youplay';
import { LabradorYoutube, LabradorYoutubeProps } from './Youtube';
import { LabradorContentTags, LabradorContentTagsProps } from './ContentTags';
import { LabradorCourseDays, LabradorCourseDaysProps } from './Course/Days';

export type LabradorComponentsProps = {
  AdTemplate: LabradorAdTemplateProps;
  Adunit: LabradorAdunitProps;
  AffiliateGallery: LabradorAffiliateGalleryProps;
  AffiliateItem: LabradorAffiliateItemProps;
  Article: LabradorArticleProps;
  ArticleFooter: LabradorArticleFooterProps;
  ArticleHeader: LabradorArticleHeaderProps;
  ArticleSlider: LabradorArticleSliderProps;
  ArticleTeaserBodytext: LabradorArticleTeaserBodytextProps;
  ArticleTeaserDefault: LabradorArticleTeaserDefaultProps;
  ArticleTeaser: LabradorArticleTeaserProps;
  Banner: LabradorBannerProps;
  BlogSlider: LabradorBlogSliderProps;
  Bodytext: LabradorBodytextProps;
  Box: LabradorBoxProps;
  BreakingNews: LabradorBreakingNewsProps;
  Byline: LabradorBylineProps;
  Checkbox: LabradorCheckboxProps;
  Columns: LabradorColumnsProps;
  ContentLock: LabradorContentLockProps;
  CourseCountdown: LabradorCourseCountdownProps;
  CoverBox: LabradorCoverBoxProps;
  CustomLinks: LabradorCustomLinksProps;
  Emphasis: LabradorEmphasisProps;
  Facebook: LabradorFacebookProps;
  FactBox: LabradorFactBoxProps;
  Fallback: LabradorFallbackProps;
  Front: LabradorFrontProps;
  Heading: LabradorHeadingProps;
  Image: LabradorImageProps;
  Instagram: LabradorInstagramProps;
  JwVideoArticle: LabradorJwVideoArticleProps;
  JwVideoShoppable: LabradorJwVideoShoppableProps;
  JwVideoTeaser: LabradorJwVideoTeaserProps;
  JwVideo: LabradorJwVideoProps;
  KeesingGames: LabradorKeesingGamesProps;
  LabradorImage: LabradorLabradorImageProps;
  LineBreak: LabradorLineBreakProps;
  List: LabradorListProps;
  Markup: LabradorMarkupProps;
  MostRead: LabradorMostReadProps;
  NewsletterSignup: LabradorNewsletterSignupProps;
  Paragraph: LabradorParagraphProps;
  Quotebox: LabradorQuoteboxProps;
  RadioButton: LabradorRadioButtonProps;
  RecommendedArticles: LabradorRecommendedArticlesProps;
  RichTextBox: LabradorRichTextBoxProps;
  Row: LabradorRowProps;
  SavedArticles: LabradorSavedArticlesProps;
  SecondNativeArticle: LabradorSecondNativeArticleProps;
  ShowsSlider: LabradorShowsSliderProps;
  Slider: LabradorSliderProps;
  TagsIndex: LabradorTagsIndexProps;
  TextMultiline: LabradorTextMultilineProps;
  TextSubtitle: LabradorTextSubtitleProps;
  TextTitle: LabradorTextTitleProps;
  Tiktok: LabradorTiktokProps;
  TrendingMenu: LabradorTrendingMenuProps;
  Twitter: LabradorTwitterProps;
  VideoReels: LabradorVideoReelsProps;
  Youplay: LabradorYouplayProps;
  Youtube: LabradorYoutubeProps;
  ContentTags: LabradorContentTagsProps;
  CourseDays: LabradorCourseDaysProps;
}

export const LabradorComponents = {
  AdTemplate: LabradorAdTemplate,
  Adunit: LabradorAdunit,
  AffiliateGallery: LabradorAffiliateGallery,
  AffiliateItem: LabradorAffiliateItem,
  Article: LabradorArticle,
  ArticleFooter: LabradorArticleFooter,
  ArticleHeader: LabradorArticleHeader,
  ArticleSlider: LabradorArticleSlider,
  ArticleTeaserBodytext: LabradorArticleTeaserBodytext,
  ArticleTeaserDefault: LabradorArticleTeaserDefault,
  ArticleTeaser: LabradorArticleTeaser,
  Banner: LabradorBanner,
  BlogSlider: LabradorBlogSlider,
  Bodytext: LabradorBodytext,
  Box: LabradorBox,
  BreakingNews: LabradorBreakingNews,
  Byline: LabradorByline,
  Checkbox: LabradorCheckbox,
  Columns: LabradorColumns,
  ContentLock: LabradorContentLock,
  CourseCountdown: LabradorCourseCountdown,
  CoverBox: LabradorCoverBox,
  CustomLinks: LabradorCustomLinks,
  Emphasis: LabradorEmphasis,
  Facebook: LabradorFacebook,
  FactBox: LabradorFactBox,
  Fallback: LabradorFallback,
  Front: LabradorFront,
  Heading: LabradorHeading,
  Image: LabradorImage,
  Instagram: LabradorInstagram,
  JwVideoArticle: LabradorJwVideoArticle,
  JwVideoShoppable: LabradorJwVideoShoppable,
  JwVideoTeaser: LabradorJwVideoTeaser,
  JwVideo: LabradorJwVideo,
  KeesingGames: LabradorKeesingGames,
  LabradorImage: LabradorLabradorImage,
  LineBreak: LabradorLineBreak,
  List: LabradorList,
  Markup: LabradorMarkup,
  MostRead: LabradorMostRead,
  NewsletterSignup: LabradorNewsletterSignup,
  Paragraph: LabradorParagraph,
  Quotebox: LabradorQuotebox,
  RadioButton: LabradorRadioButton,
  RecommendedArticles: LabradorRecommendedArticles,
  RichTextBox: LabradorRichTextBox,
  Row: LabradorRow,
  SavedArticles: LabradorSavedArticles,
  SecondNativeArticle: LabradorSecondNativeArticle,
  ShowsSlider: LabradorShowsSlider,
  Slider: LabradorSlider,
  TagsIndex: LabradorTagsIndex,
  TextMultiline: LabradorTextMultiline,
  TextSubtitle: LabradorTextSubtitle,
  TextTitle: LabradorTextTitle,
  Tiktok: LabradorTiktok,
  TrendingMenu: LabradorTrendingMenu,
  Twitter: LabradorTwitter,
  VideoReels: LabradorVideoReels,
  Youplay: LabradorYouplay,
  Youtube: LabradorYoutube,
  ContentTags: LabradorContentTags,
  CourseDays: LabradorCourseDays,
}
