import { tw } from '@/utils/tw';
import BaseBreakingNewsTheme from 'base/components/BreakingNews/theme';

const Icon = tw.theme({
  extend: BaseBreakingNewsTheme.Icon,
});

const BreakingNews = tw.theme({
  extend: BaseBreakingNewsTheme,
  variants: {
    variant: {
      primary: {
        item: `bg-white`,
        blip: `bg-cherry-blossom-500 before:bg-cherry-blossom-500 after:bg-cherry-blossom-500`,
        headline: `text-headline-xs sm:text-headline-sm`,
        label: `text-cherry-blossom-500 text-headline-xs sm:text-headline-sm`,
      },
    },
  },
});

export default Object.assign(BreakingNews, { Icon });
