import { tw } from '@/utils/tw';

const Banner = tw.theme({
  slots: {
    base: [
      'w-full',
      'p-2.5',

      'sm:p-10',
      'sm:container-col-md-4:p-6',

      'md:p-16',
      'md:py-8',
      'md:container-col-md-2:px-24',
      'md:container-col-sm-12:px-48',
      'md:container-col-md-4:py-10',

      'lg:container-col-md-4:px-58',
      'lg:container-col-md-4:py-10',

      'xl:px-24',
      'xl:py-6',
      'xl:container-col-md-4:px-68',
      'xl:container-col-md-4:py-14',
      'flex',
      'flex-col',
      'relative',
      'overflow-hidden',
      'not-prose',
      'bg-yellow-sea-100',
    ],
    content: [
      'w-full',

      'pt-24',
      'px-4',
      'pb-1',
      'container-col-md-4:px-2',
      'container-col-md-4:pt-24',
      'container-col-md-4:pb-4',

      'sm:px-12',
      'sm:pt-22',
      'sm:pb-4',
      'sm:container-col-md-4:px-12',
      'sm:container-col-md-4:pt-22',
      'sm:container-col-md-4:pb-4',

      'md:pt-20',
      'md:px-8',
      'md:pb-4',
      'md:container-col-md-4:px-16',
      'md:container-col-md-4:pt-22',
      'md:container-col-md-4:pb-6',

      'lg:container-col-md-4:px-22',
      'lg:container-col-md-4:pt-12',
      'lg:container-col-md-4:pb-6',

      'xl:pt-20',
      'xl:px-8',
      'xl:pb-4',
      'xl:container-col-md-4:px-22',
      'xl:container-col-md-4:pt-14',
      'xl:container-col-md-4:pb-8',

      '[&_ul]:ml-8',
      '[&_li]:mt-2',
      'bg-white',
      'sm:shadow-yellow-sea-300',
      'relative',
      'flex',
      'flex-col',
      'sm:shadow-[0px_0px_0px_2px]',
      '[&_ul]:list-disc',
    ],
    backgroundImage: ['text-yellow-sea-400', 'w-[1216px]', 'lg:w-full', 'absolute', 'bottom-0', 'right-0'],
    contentHeadline: ['text-headline-md', '!font-bold', 'sm:text-center'],
    contentDescription: ['text-preheadline-md', 'mb-0', 'mt-4', '[li_&]:mt-0'],
    contentLogo: ['h-16', 'w-20', 'sm:h-18', 'sm:w-22', 'absolute', 'top-3', 'left-3', 'sm:top-2', 'sm:left-2'],
  },
});

export default Banner;
